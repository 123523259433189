import {IExam} from '../../../shared/models/test.model';

export const headOfEducationalOrganization: IExam = {
    id: 'headOfEducationalOrganization',
    title: 'Руководитель организации образования',
    topics: [
        {
            id: 'workCode',
            title: 'Трудовой кодекс РК',
            questions: [],
            questionsCount: 10,
            passingScore: 5
        },
        {
            id: 'educationLaw',
            title: 'Закон «Об образовании»',
            questions: [],
            questionsCount: 10,
            passingScore: 5
        },
        {
            id: 'antiCorrupitonForHearOfEducation',
            title: 'Закон «O противодействии коррупции»',
            questions: [],
            questionsCount: 10,
            passingScore: 5
        },
        {
            id: 'tutorStatusLaw',
            title: 'Закон «О статусе педагога»',
            questions: [],
            questionsCount: 10,
            passingScore: 5
        },
        {
            id: 'approvalOfStandards',
            title: 'Закон об утверждении государственных общеобязательных стандартов',
            questions: [],
            questionsCount: 5,
            passingScore: 3
        },
        {
            id: 'typicalRules',
            title: 'Закона об утверждении типовых правил',
            questions: [],
            questionsCount: 5,
            passingScore: 3
        },
    ],
    time: 180,
    questionsTotal: 50,
    passingScore: 26
};
